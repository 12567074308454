<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">售后订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>创建售后订单</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="forms" :rules="rules" ref="forms" :label-width="110" style="margin:0 30px;">
      <el-row :gutter="10">
        <el-col :span="7">
          <el-form-item label="原订单编号">
            <el-input v-model="forms.orderNo" @keyup.enter="queryOrderEvent" prefix-icon="Search"
              placeholder="扫码或输入订单号回车查询Enter" style="max-width: 250px;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="销售渠道">
            <el-select v-model="forms.channel" placeholder="渠道" @change="queryOrderEvent" style="max-width: 130px;">
              <el-option v-for="dd in dict.channels" :key="dd.value" :label="dd.label" :value="dd.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="display: none;">
          <el-form-item label="货品公司">
            <!-- <el-cascader v-model="forms.companyId" :options="companys" clearable @change="queryOrderEvent"
              placeholder="请选择订单归属公司(非必填)" /> -->
            <el-tree-select ref="companys" v-model="forms.companyId" :data="companys" @change="queryOrderEvent" clearable check-strictly
              :render-after-expand="false" style="width: 200px;" placeholder="请选择订单归属公司(非必填)" />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="售后货品批次号">
            <el-input v-model="forms.serialCode" @keyup.enter="queryOrderEvent" placeholder="货品批次号(非必填)"
              style="max-width: 130px;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="queryOrderEvent" style="margin-left: 25px;">查询订单</el-button>
        </el-col>
      </el-row>

      <hr style="margin:0 0 12px;">

      <template v-for="(item, index) in forms.returnOrders" :key="item.id">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item :label="'订单货品' + (index + 1)" style="margin-bottom: 0; font-weight: 700;" label-width="90px">
              <el-col :span="20">
                <hr>
              </el-col>
              <el-col :span="4">
                <el-button @click="deleteItem(item)" size="small">取消子订单</el-button>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="" label-width="50px">
              <el-col :span="6">
                订单号：<span class="space20">{{ item.orderNo }}</span>
              </el-col>
              <el-col :span="6">
                订单时间：<span class="space20">{{ item.platformOrderDate }}</span>
              </el-col>
              <el-col :span="5">
                订单平台：<span class="space20">{{ item.platformName }}</span>
              </el-col>
              <el-col :span="6">
                子单号：<span class="space20">{{ item.PlatformOrderSubNo }}</span>
              </el-col>

              <el-col :span="24">
                产品名称：<span class="space20">{{ item.platformProductName }}</span>
              </el-col>
              <!-- <el-col :span="6">
                系统款号：<span class="space20">{{ item.detailCode }}</span>
              </el-col>
              <el-col :span="6">
                批次号：<span class="space20">{{ item.serialCode }}</span>
              </el-col> -->
              <el-col :span="6">
                品牌名称：<span class="space20">{{ item.brandName }}</span>
              </el-col>
              <el-col :span="6">
                品牌款号：<span class="space20">{{ item.brandModelCode }}</span>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="售后类型">
              <el-select v-model="item.returnType" placeholder="请选择售后类型">
                <el-option key=0 label="发货前退款" :value="parseInt(0)"></el-option>
                <el-option key=10 label="退货退款" :value="parseInt(10)"></el-option>
                <el-option key=20 label="仅退款" :value="parseInt(20)"></el-option>
                <el-option key=30 label="售后换新" :value="parseInt(30)"></el-option>
                <el-option key=50 label="其他(维修等)" :value="parseInt(50)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="售后渠道">
              <el-select v-model="item.channel" placeholder="渠道" style="max-width: 130px" disabled>
                <el-option v-for="dd in dict.channels" :key="dd.value" :label="dd.label" :value="dd.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="货品库房">
              <el-select v-model="item.storeCode" placeholder="库房" style="max-width: 150px">
                <el-option v-for="dd in dict.stores" :key="dd.value" :label="dd.label" :value="dd.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
          </el-col>
          <el-col :span="6">
            <el-form-item label="售后款号" prop="detailCode">
              <el-input v-model="item.detailCode" style="max-width:180px" placeholder="未发货出库可不填" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发货批次号" prop="serialCode">
              <el-input v-model="item.serialCode" style="max-width:150px" placeholder="未发货出库可不填" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="退货数量及金额" prop="returnQty">
              <el-input-number v-model="item.returnQty" :min="0" :max="10000000" :precision="0" placeholder="数量"
                style="max-width:120px"></el-input-number>
              <el-input-number v-model="item.returnFee" :min="0" :max="10000000" :precision="2" placeholder="金额"
                style="max-width:170px; margin-left: 20px;"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="赠品库房">
              <el-select v-model="item.fitStoreCode" placeholder="无赠品出库可不填" style="max-width: 170px">
                <el-option v-for="dd in dict.stores" :key="dd.value" :label="dd.label" :value="dd.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赠品款号" prop="fitDetailCode">
              <el-input v-model="item.fitDetailCode" style="max-width:180px" placeholder="无赠品出库可不填" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赠品批次号" prop="serialCode">
              <el-input v-model="item.fitSerialCode" style="max-width:150px" placeholder="无赠品出库可不填" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="寄回快递" prop="returnPostName">
              <el-input v-model="item.returnPostName" style="max-width:180px" placeholder="快递公司名称(可填写无)" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="快递单号" prop="returnPostNo">
              <el-input v-model="item.returnPostNo" style="max-width:180px" placeholder="快递单号(可填写无)" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="运费模式">
              <el-select v-model="item.returnPostModel" placeholder="请选择运费模式">
                <el-option key="用户承担" label="用户承担" value="用户承担"></el-option>
                <el-option key="商家承担" label="商家承担" value="商家承担"></el-option>
                <el-option key="平台承担" label="平台承担" value="平台承担"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <el-row :gutter="10" v-if="forms.returnOrders.length > 0">
        <el-col :span="24">
          <hr style="margin:0 0 12px;">
        </el-col>
        <el-col :span="12">
          <el-form-item label="售后订单号" prop="returnNo">
            <el-input v-model="forms.returnNo" style="max-width:220px" placeholder="渠道平台的售后单号" />
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item label="售后原因" prop="returnReason">
            <el-input v-model="forms.returnReason" style="max-width:480px" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注">
            <el-input v-model="forms.remark" style="max-width:450px" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" @click="saveReturnEvent">保存售后单</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>
<script>
import { queryOrder, submitReturnOrder } from "@/api/order";
export default {
  name: "orderReturnDetail",
  components: {},
  data() {
    return {
      forms: {
        returnOrders: [],
        channel: null,
        companyId: null
      },

      dict: {
        stores: [],
        channels: [],
      },

      companys: [],
      companyId: '',

      rules: {
        // returnReason: [
        //   { required: true, message: "请填写具体退货原因", trigger: "blur" },
        // ],
      },
    };
  },
  methods: {
    queryOrderEvent() {
      if (!this.forms.channel) {
        this.$message({ type: "info", message: "请选择售后订单的渠道平台", });
        return;
      }
      if (!this.forms.orderNo) {
        this.$message({ type: "info", message: "请选择输入售后的原订单号", });
        return;
      }

      queryOrder(this.forms.channel, this.forms.orderNo, this.forms.serialCode, this.forms.companyId).then((res) => {
        this.forms.returnOrders = res.data;
      })
        .catch(() => {
          this.$message({ type: "info", message: "查询失败", });
        });
    },
    addReturnOrder() {
      this.forms.returnOrders = [];
      that.$refs.detailDialog.toggle();
    },

    saveReturnEvent() {
      let that = this;

      let valid = true;
      this.forms.returnOrders.forEach(item => {
        if (item.returnType == undefined) { valid = false; }
        //if ((item.detailCode || '').length == 0) { valid = false; }
        //if ((item.serialCode || '').length == 0) { valid = false; }
        if (item.returnQty <= 0 || item.returnFee < 0) { valid = false; }

        item.returnType = parseInt(item.returnType);
      });
      if (!valid) {
        this.$message({ type: "info", message: "请补充完善要退货的订单货品信息", });
        return false;
      }

      this.$refs.forms.validate((valid) => {
        if (!valid) return;
        that.forms.returnOrders.forEach(item => {
          item.returnReason = that.forms.returnReason;
          item.remark = that.forms.remark;
        });
        submitReturnOrder(that.forms.returnOrders).then((res) => {
          that.$message({ type: res.type, message: res.content });
          if (res.code == 200) {
            that.forms.channel = null;
            that.forms.returnOrders = [];

            that.$router.push('/order/returnInfo');
          }
        });
      });
    },

    deleteItem(item) {
      this.forms.returnOrders = this.forms.returnOrders.filter(res => res.returnNo != item.returnNo);
    },
  },
  watch: {

  },
  created: function () {
    this.forms.orderNo = this.$route.query.orderNo || '';
    this.forms.channel = this.$route.query.channel || '';
    this.forms.serialCode = this.$route.query.serialCode || '';
    if (this.forms.orderNo && this.forms.channel) {
      this.queryOrderEvent();
    }

    this.$kaung.companys().then((res) => {
      this.companys = res;
    });

    this.$kaung.dictionary("stock", "store").then(res => this.dict.stores = res);
    this.$kaung.dictionary("stock", "channel").then((res) => { this.dict.channels = res; });
  },
  computed: {
  }
};
</script>
<style lang="scss" scoped>
.space20 {
  margin-right: 20px;
  color: #000;
}
</style>